<template>
  <div id="carConfig">
    <div class="header">
      <span v-if="status != 3" @click="changeStatus(1)" :class="status == 1?'active':''">{{standardName}}</span>
      <span v-if="status != 3" @click="changeStatus(2)" :class="status == 2?'active':''">{{optionalName}}</span>
      <span v-if="status == 3" @click="changeStatus(3)" :class="status == 3?'active':''">{{manualName}}</span>
    </div>
    <template v-if="status == 1">
      <div class="content" v-for="item in standard.configTypeList" :key="item.id">
        <span class="title">{{item.modelConfigItemTypeName}}</span>
        <div class="list" v-for="i in item.configItemList" :key="i.id">
          <span class="item">{{i.modelConfigItemName}}</span>
          <span class="item">{{i.modelConfigItemValue}}</span>
        </div>
      </div>
    </template>
    
    <template v-if="status == 2">
      <div v-for="item in optional.configTypeList" :key="item.id">
        <div class="box_title">{{item.modelConfigItemTypeName}}</div>
        <div class="box">
          <span v-for="i in item.configItemList" :key="i.id" class="title">{{i.modelConfigItemName}}</span>
        </div>
      </div>
    </template>

    <template v-if="status == 3">
      <div class="content" v-for="item in manual.configTypeList" :key="item.id">
        <span class="title">{{item.modelConfigItemTypeName}}</span>
        <div class="list" v-for="i in item.configItemList" :key="i.id">
          <span class="item">{{i.modelConfigItemName}}</span>
          <span class="item">{{i.modelConfigItemValue}}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {getVehicleModelConfig} from "@/utils/api"
export default {
  components: {},
  data () {
    return {
      status:1,
      standard:{},
      standardName:'',
      optional:{},
      optionalName:'',
      manual:{},
      manualName:'',
    };
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id;
    this.modelId = this.$route.query.modelId;
    this.getVehicleModelConfig()
  },
  methods: {
    getVehicleModelConfig(){
      const that = this;
      let param = {
        vehicleId: that.id,
        modelId:that.modelId,
        isNoAuthorization: true,
      };
      getVehicleModelConfig(param).then(res=>{
        that.standard = res.data.filter(e=>e.key == 'standard')[0];
        that.optional = res.data.filter(e=>e.key == 'optional')[0]
        this.manual = res.data.filter(e=>e.key == 'manual')[0];

        if(that.standard){
          that.standardName = that.standard.name;
          that.status = 1;
        }
        if(that.optional){
          that.optionalName = that.optional.name;
          that.status = 2;
        }
        if(that.manual){
          that.manualName = that.manual.name;
          that.status = 3;
        }

      })
    },
    changeStatus(e){
      this.status = e;
    }
  }
}

</script>
<style lang='scss'>
#carConfig{
  background: #F3F5F7;
  .header{
    display: flex;
    background: #ffffff;
    position: sticky;
    top:0;
    span{
      text-align: center;
      line-height: 50px;
      flex-grow: 1;       
      font-family: PingFang-SC-Regular;
      font-size: 14px;
      color: #292D33;
    }
    .active{
      border-bottom:1px solid #327FFF;
      color:#327FFF;
    }
  }
  .content{
    margin-top: 10px;
    border-radius: 16px;
    padding:15px;
    width: calc( 100% - 30px );
    background: #ffffff;
    .title{
      font-family: PingFang-SC-Medium;
      font-size: 16px;
      color: #292D33;
      font-weight: bold;
    }
    .list{
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      .item{
        font-family: PingFang-SC-Regular;
        font-size: 14px;
        color: #292D33;
      }
    }
  }
  .box_title{
    padding-left: 15px;
    line-height: 50px;
    font-family: PingFang-SC-Medium;
    font-size: 16px;
    color: #292D33;
    font-weight: bold;
  }
  .box{
    background: #FFFFFF;
    border-radius: 16px;
    padding: 0 15px;
    .title{
      width: 100%;
      display: block;
      font-family: PingFang-SC-Regular;
      font-size: 14px;
      text-align:left;
      line-height: 50px;
      color: #292D33;
      border-bottom: 1px solid #E9E9E9;
      &:last-child{
        border:none;
      }
    }
  }
}
</style>